<template>
  <div class="d-flex flex-column">
    <div class="d-flex">
      <div class="secondary rounded-lg mt-8" style="height: 24px; width: 8px" />

      <v-card class="py-6 px-8 mb-4" color="surface" width="100%" flat tile>
        <div class="text-h5 font-weight-bold mb-6">
          <span class="saira"> Dados Escolares </span>
        </div>

        <v-form v-model="valid" ref="form" autocomplete="off" class="mb-6">
          <div @keypress.enter.prevent="submit()" class="mb-4">
            <v-row no-gutters>
              <v-col cols="12" md="6" class="pr-md-2">
                <div class="label-input">Cidade</div>
                <v-autocomplete
                  v-model="form.schoolCity"
                  ref="schoolCity"
                  class="rounded-lg"
                  background-color="accent"
                  placeholder="Selecione sua escola"
                  item-value="city"
                  item-text="city"
                  :items="cities"
                  :rules="rules"
                  solo
                  flat
                  @focus="$refs.schoolCity.activateMenu()"
                />
              </v-col>

              <v-col cols="12" md="6" class="pl-md-2">
                <div class="label-input">Escola</div>
                <v-autocomplete
                  v-model="form.schoolId"
                  ref="school"
                  no-data-text="Nenhuma escola encontrada, selecione a opção 'Outra' e digite sua escola"
                  class="rounded-lg"
                  background-color="accent"
                  placeholder="Selecione sua escola"
                  item-value="id"
                  item-text="name"
                  :rules="rules"
                  :disabled="!form.schoolCity"
                  :items="schools"
                  solo
                  flat
                  @focus="$refs.school.activateMenu()"
                />
              </v-col>
            </v-row>

            <div v-if="form.schoolId == 'other'">
              <v-row no-gutters>
                <v-col cols="12" md="6" class="pr-md-2">
                  <div class="label-input">Nome da escola</div>
                  <v-text-field
                    v-model="form.schoolName"
                    class="rounded-lg"
                    background-color="accent"
                    placeholder="Nome da sua escola"
                    :rules="rules"
                    solo
                    flat
                  />
                </v-col>

                <v-col cols="12" md="6" class="pl-md-2">
                  <div class="label-input">Bairro</div>
                  <v-text-field
                    v-model="form.schoolDistrict"
                    class="rounded-lg"
                    background-color="accent"
                    placeholder="Bairro"
                    :rules="rules"
                    solo
                    flat
                  />
                </v-col>
              </v-row>
            </div>
          </div>
        </v-form>

        <section class="mb-6">
          <!-- has proof -->
          <div
            v-if="localUser.schoolCertificate"
            class="d-flex flex-wrap justify-md-end"
          >
            <v-btn
              class="rounded-r-0 rounded-l-lg text-none mr-md-2 mb-2 mb-md-0"
              color="primary"
              target="_blank"
              :block="$vuetify.breakpoint.smAndDown"
              :href="localUser.schoolCertificate"
              outlined
            >
              Comprovante de matrícula
            </v-btn>

            <v-btn
              class="rounded-l-0 rounded-r-lg text-none accent"
              :block="$vuetify.breakpoint.smAndDown"
              depressed
              @click="changeProof()"
            >
              Alterar
            </v-btn>
          </div>

          <!-- empty proof -->
          <div v-else class="d-flex flex-wrap justify-md-end">
            <v-btn
              class="rounded-l-0 rounded-r-lg text-none mr-md-2 mb-2 mb-md-0"
              color="primary"
              :block="$vuetify.breakpoint.smAndDown"
              outlined
              @click="changeProof()"
            >
              Enviar comprovante
            </v-btn>
          </div>

          <input
            accept="image/jpeg,image/png,application/pdf,image/x-eps"
            ref="uploader"
            class="d-none"
            type="file"
            @change="onFileChanged($event)"
          />
        </section>
      </v-card>
    </div>

    <div class="d-flex justify-end">
      <v-btn
        class="rounded-r-0 rounded-l-lg text-none mr-6"
        color="surface"
        width="96"
        large
        @click="startForm()"
      >
        Cancelar
      </v-btn>

      <v-btn
        class="rounded-l-0 rounded-r-lg text-none"
        color="primary"
        width="96"
        large
        @click="submit()"
      >
        Salvar
      </v-btn>
    </div>

    <loader-hover v-if="loading" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { updateUserData, changeProof } from "@/services/user.js";
import { getCities, getSchools } from "@/services/schools";
import { displayAlert } from "@/utils";

export default {
  data() {
    return {
      loading: false,
      valid: false,
      gradeTypes: [
        {
          label: "Ensino Fundamental",
          value: "f",
        },
        {
          label: "Ensino Médio",
          value: "m",
        },
        {
          label: "Ensino Superior",
          value: "s",
        },
      ],
      cities: [],
      schools: [],
      form: {
        schoolId: null,
        schoolName: "",
        schoolDistrict: "",
        schoolCity: "",
        grade: "",
      },
    };
  },

  beforeMount() {
    this.startForm();
    this.getCities();
  },

  watch: {
    [`form.schoolCity`]() {
      this.schools = [];
      this.getSchools();
    },
  },

  computed: {
    ...mapState(["user", "localUser"]),

    rules() {
      return [(v) => !!v || "Campo Obrigatório"];
    },

    currentState() {
      return "PB";
    },
  },

  methods: {
    async submit() {
      try {
        this.loading = true;

        const payload = {
          grade: this.form.grade,
        };

        if (this.form.schoolId === "other") {
          payload.schoolComplementName = this.form.schoolName;
          payload.schoolComplementDistrict = this.form.schoolDistrict;
          payload.schoolComplementCity = this.form.schoolCity;
        } else {
          payload.schoolId = this.form.schoolId;
        }

        await updateUserData(payload).then((res) => {
          this.displayAlert(res.message);
          this.$root.$emit("refresh-user");
        });
      } catch (err) {
        this.displayAlert(err.data.message, 1);
      } finally {
        this.loading = false;
      }
    },

    async getCities() {
      try {
        this.loading = true;

        await getCities(this.currentState).then((res) => {
          this.cities = res;
        });
      } catch (err) {
        this.displayAlert("Falha ao buscar Cidades", 1);
      } finally {
        this.loading = false;
      }
    },

    async getSchools() {
      try {
        this.loading = true;

        const payload = {
          city: this.form.schoolCity,
          state: this.currentState,
        };

        await getSchools(payload).then((res) => {
          this.schools = [{ name: "Outra", id: "other" }, ...res];
        });
      } catch (err) {
        this.displayAlert("Falha ao buscar Escolas", 1);
      } finally {
        this.loading = false;
      }
    },

    startForm() {
      if (this.localUser.school) {
        this.form.schoolId = this.localUser.school.id;
        this.form.schoolName = this.localUser.school.name;
        this.form.schoolDistrict = this.localUser.school.district;
        this.form.schoolCity = this.localUser.school.city;
      } else {
        this.form.schoolId = "other";
        this.form.schoolName = this.localUser.schoolComplementName;
        this.form.schoolDistrict = this.localUser.schoolComplementDistrict;
        this.form.schoolCity = this.localUser.schoolComplementCity;
      }

      this.form.grade = this.localUser.grade.substr(
        this.localUser.grade.length - 1
      );
    },

    changeProof() {
      window.addEventListener("focus", () => {}, { once: true });
      this.$refs.uploader.click();
    },

    async onFileChanged(event) {
      try {
        this.loading = true;

        const formData = new FormData();
        formData.append("file", event.target.files[0]);

        await changeProof(formData).then(() => {
          this.$root.$emit("refresh-user");
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async handleDialog() {
      await this.$refs.dialog.openDialog();
    },

    displayAlert,
  },
};
</script>

<style></style>
